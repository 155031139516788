import React from 'react'
import {
  IonContent,
  IonImg,
  IonPage,
  IonRippleEffect,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonAlert,
} from '@ionic/react'
import { GuruSlider, GuruSlide } from '../../components'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'
import ReCAPTCHA from 'react-google-recaptcha'

//MODELS
import UserModel from './../../models/User'
import SettingsModel from './../../models/Settings'
import Office from '../../models/Office'

import {logError, sendMetric} from '../firebase/firebaseTags'

import { loginv2, validateCaptcha } from '../../clients/login'
import { requestSms } from '../../clients/validateSms'
import termsConditionModel from '../../models/TermsConditionModel'

import GlobalComponents from '../../components'
import RegisterUserView from '../register-user/RegisterUser'

import { getAppCurrentVersion } from '../../clients/version'
import { appShouldUpdate } from '../../utils/appShouldUpdate'


//UTILS
import { dniByCountry, phoneAsUsernameCountries, supportedCountries } from '../../utils/countriesTexts'
import { getGeoInfo } from '../../utils/currentCountry'
import { currentApp } from '../../utils/currentCountry'

import arrowBackWhite from '../../assets/icons/arrow-back-white.svg'

//COMPONENTS
import LoadingCover from '../../components/loading-cover/LoadingCover'
import TextInput from '../../components/input-form/TextInput'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import ContactSupport from '../../components/contact-support/ContactSupport'
import AlertModal from '../../components/modals/AlertModal'
import DniField from '../../components/dni-field/DniField'
import PhoneUsernameField from '../../components/phone-username-field/PhoneUsernameField'

// HOC for App Updates Provider

import { AppLauncher } from '@capacitor/app-launcher'
import './Login.scss'
import { isObjectEmpty } from '../../utils/objects'
import { Capacitor } from '@capacitor/core'
const qs = require('qs');

interface userValidationFunc {
  (user: any, approved: boolean): void
}

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: UserModel
  settings: SettingsModel
  tracking?: any
  offices: Office
  showTermsConditions: termsConditionModel
}

interface Form {
  password: string
}

type ShowFormType = 'none' | 'login' | 'register'

interface State<T> {
  data: { [name in keyof T]: T[name] }
  validation: { [name in keyof T]: boolean }
  validationClass: { [name in keyof T]: string }
  isFormValid: boolean
  loading: boolean
  loadingCover: boolean
  showError: boolean
  messageError: string
  user: any
  showtype: ShowFormType
  captchaToken: string
  loginShowCases: any
  appVersion: any
  appInfo: any
  rutError: boolean
  userName: string
  approvedUserName: boolean
  countryCode: string
  countryName: string
  countryAvailable : any 
  onboarding: any
  pageState: PageStates
  valueInputText: any
  validationInput: boolean
  validationText: string | number
  // nextUpdate: any
}

enum PageStates {
  LOADING,
  LOGIN,
  ONBOARDING
}
const captcha_client: string = process.env.REACT_APP_CAPTCHA_CLIENT_ID_INVISIBLE || ''

class Login extends React.PureComponent<Props, State<Form>> {
  state: State<Form> = {
    user: null,
    showtype: 'none',
    data: {
      password: '',
    },
    loading: false,
    loadingCover: false,
    validation: {
      password: false,
    },
    isFormValid: false,
    showError: false,
    messageError: '',
    captchaToken: '',
    loginShowCases:[] ,
    appVersion: null,
    appInfo: {},
    rutError: false,
    validationClass: {
      password: '',
    },
    countryCode: '',
    userName:  '',
    approvedUserName: false,
    countryName: '',
    countryAvailable: '',
    onboarding: [],
    pageState: PageStates.LOADING,
    valueInputText: "",
    validationInput: false,
    validationText: "",
    // nextUpdate: null,
    // appVersionUpdateInfo: { update: false, url: null, required: false },
  }

  captchaRef = React.createRef<any>()
  mounted: Boolean = false

  async componentDidMount() {
    // ir a obtener si se debe o no actualizar
    let appCurrentVersionValue = await getAppCurrentVersion()
    const shouldUpdate = await appShouldUpdate(
      appCurrentVersionValue.versionAndroid,
      appCurrentVersionValue.versionIos
    )

    let storeUrl = null

    if(Capacitor.getPlatform() === 'android') {
      storeUrl = appCurrentVersionValue.androidStoreUrl
    }

    if(Capacitor.getPlatform() === 'ios') {
      storeUrl = appCurrentVersionValue.iosStoreUrl
    }

    appCurrentVersionValue.shouldUpdate = shouldUpdate
    appCurrentVersionValue.url = storeUrl

    this.setState((prevState) => {
      return {
        ...prevState,
        appVersion: appCurrentVersionValue,
      }
    })
    
    if (this.props.location.state){
      const countrySelect:any = this.props.location.state
      countrySelect && this.setState({
        countryCode:countrySelect.countryCode,
        countryName:countrySelect.countryName
      }) 
      
      await this.loginShowCasePhoto(countrySelect)
      
    } else {
      const country = await getGeoInfo()
      if (country === null) {
        this.props?.history?.push("/no-country")
      }
  
      country && this.setState({
        countryCode: country.countryCode,
        countryName: country.countryName,// country.country_name === 'Peru' ?  'Perú' : country.country_name
      }, this.noCountryDetect ) 
    
      country &&  await this.loginShowCasePhoto(country)
    }

    this.saveQueryParams()

    // let info = await Device.getInfo()
    // const appInfoCapacitor = await App.getInfo().catch(() => null)
    // const nextUpdate = String(localStorage.getItem('@update'))

    // if (info.platform !== 'web') {
    //   info = Object.assign(info, appInfoCapacitor, { appBuild: appInfoCapacitor?.build })

    //   if (appInfoCapacitor?.version && nextUpdate) {
    //     const response = await this.props.user.appVersion(info)
    //     this.setState({
    //       appVersionUpdateInfo: {...response, update: true, s: true},
    //       nextUpdate: JSON.parse(nextUpdate),
    //     })
    //   }
    // }


    // const response = await this.props.user.appCurrentVersion()
    // this.setState({
    //   appVersion: response,
    //   appInfo: { ...info, appVersion: appInfoCapacitor?.version || "" }
    // })
    
    const countryAvailable = await currentApp()
    this.setState({countryAvailable})

    const onboarding = await this.props.user.onboarding()
    if (onboarding !== undefined) this.setState({onboarding})

    const requestRegistration = localStorage.getItem('requestRegistration')
    if (requestRegistration) {
      this.setState({showtype: 'register'})
      localStorage.removeItem('requestRegistration')
    }

    this.validateOnboarding()
  }

  saveQueryParams = () => {
    try {
      localStorage.removeItem('marketingRegisterParams');
      const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if (params && !isObjectEmpty(params)) {
        localStorage.setItem('marketingRegisterParams', JSON.stringify(params));
      }
    } catch (error) {
      console.error(error);
      return;
    }
  }

  getMatchCookies = (currentCookie:any) => { 
    const isEqual:any = currentCookie.indexOf("=");;
    return currentCookie.substring(isEqual+1);
  }
  
  cookiesMatched = () => this.setState({pageState: PageStates.LOGIN})
  
  noCookiesMatched = () => {
    this.setState({pageState: PageStates.ONBOARDING})
    document.cookie = `onboarding=${JSON.stringify(true)}`;
  }
  
  validateOnboarding  = () => {
    const cookies:any =  document.cookie.split(";")
    let matchCookies= null
    
    for (let index = 0; index < cookies.length; index++) {
      const validateOnboarding = cookies[index].indexOf('onboarding');
    
      if(validateOnboarding === 1) matchCookies = this.getMatchCookies(cookies[index])
    }
    
    if(matchCookies) this.cookiesMatched()
    if(!matchCookies) this.noCookiesMatched()
  }
  
  onButtonClick = async () => {
    try {
      this.setState({
        loading: true,
      })

      if(process.env.REACT_APP_SHOW_CAPTCHA === 'true'){
        const token = await this.captchaRef.current.executeAsync()
        const captchaResult = await validateCaptcha(token)
        
        if(!captchaResult.success) {
          this.setState({
            showError: true,
            messageError: 'Captcha inválido',
            loading: false,
          })
          return
        }
      }
     
      const lastToken = localStorage.getItem('@last_token_access')
      const user:any = this.state.userName
      let loginResult: any 

      if(lastToken) {
        loginResult = await loginv2(user.username, this.state.data.password, lastToken)
      } else {
        loginResult = await loginv2(user.username, this.state.data.password)
      }
      
      if (loginResult && loginResult.invalidPassword) {
        await this.setState({
          loading: false,
          showError: true,
          messageError: `El ${dniByCountry()} o la contraseña es incorrecta. Inténtalo de nuevo.`,
          captchaToken: ''
        })
        this.captchaRef.current.reset();
        logError('Contraseña inválida', 'login_error')
      } else if (loginResult && loginResult.userNotFound) {
        await this.setState({
          loading: false,
          showError: true,
          messageError: `El ${dniByCountry()} o la contraseña es incorrecta. Inténtalo de nuevo.`,
          captchaToken: ''
        })
        this.captchaRef.current.reset();
        logError('Usuario no encontrado', 'login_error')
      } else {
        if (loginResult)  {
          await this.setState({
            loading: false,
            loadingCover: true
          })
          this.validPhone(loginResult)
          sendMetric('login')
        }
      }
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      await this.setState({
        loading: false,
        loadingCover: false,
        showError: true,
        messageError: 'El RUT o la contraseña es incorrecta. Inténtalo de nuevo.',
        validationClass: {
          password: '',
        },
      })
      logError('login_error', 'Error de usuario o contraseña')
    }
  }

  validPhone = async (loginResult:any) => {
    if(loginResult.user.smsIsValid === true){
      await this.props.user.authenticateWith(loginResult)
      setTimeout(() => {
        this.props?.history?.push('/home', Object.assign(loginResult, this.state.data))
      }, 2500)

    }else{
      requestSms(loginResult.user.phone, loginResult.user.username).then(smsResult => {
       
        loginResult.fromLogin = true
        delete smsResult.MessageID
        smsResult.haxSms = smsResult.hashedCode
        const phone = {"phone": true}
   
        this.props?.history?.push('/confirm-sms', 
          Object.assign(loginResult, this.state.data, smsResult, phone )
        )
      })
    }
  }

  onChangeInput = (event: any) => {
    const {approvedUserName} = this.state
    const validation = event.target.value

    if(validation?.length > 3 && validation?.length <= 12) {
      this.setState({
        validationInput: true,
        valueInputText: validation,
        validationText: event.target.value,
        data: {
          password: validation
        },
      })

      if(approvedUserName) {
        this.setState({
          isFormValid: true
        })
      }
    } else {
      this.setState({
        validationInput: false,
        valueInputText: validation,
        validationText: event.target.value
      })
    }
  }

  hideToast = () => {
    this.setState({
      showError: false,
      messageError: '',
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false,
      messageError: '',
    })
  }

  showButton = (value:any) => {
    switch (value) {
      case 'login':
        this.setState({
          showtype: 'login'
        });
        break;
      case 'register':
        this.setState({
          showtype: 'register',
          data: {
            password: '',
          },
        })
        break;
    }
  }

  goProfile = (value:string) =>{
    this.setState({
      showtype:"login"
    })
  }

  validateUser = (user:any, approved:boolean) =>{
    this.setState({
      userName: user,
      approvedUserName: approved,
    })

    const isFormValid =  this.state.data.password && approved ? true : false
    this.setState({ isFormValid:isFormValid })
  }

  onClickInOnboarding = (showType: ShowFormType) => {
    this.setState({
      showtype: showType
    })
  }

  loginShowCasePhoto = async (country:any) => {
    const resultLogin = await this.props.user.loginShowCasePhoto(country.countryCode)
    resultLogin && resultLogin.length > 0 &&  
    resultLogin.map((r:any) => {
      return(
        this.setState({
          showtype: 'none'
        })
      )
    }) 
    this.setState({
      loginShowCases: resultLogin && resultLogin.length && resultLogin
		})

  }

  noCountryDetect = () => {
    const { countryCode } = this.state
    if (!supportedCountries.includes(countryCode)) this.props?.history?.push('/no-country')
  }

  onclickNextPagePathOnboarding = () => {
    this.setState({
      pageState: PageStates.LOGIN
    })
  }
  
  validateLoginRegister = (item:any) => {
    const { loginShowCases } = this.state
    return loginShowCases.length && loginShowCases[0].sectionsVisibility.find((loginShowCases:any) => loginShowCases.section === item)
  }
  
  validateClassNameLogin = () => this.validateLoginRegister('Login').visible  && this.validateLoginRegister('Register').visible && 'loginSegment'
  
  validateCountry = () => {
    const { countryCode } = this.state
    return supportedCountries.includes(countryCode)
  }

  retrieveCaptchaToken = (captchaToken: any) => this.setState({ captchaToken: this.validateCaptchaToken(captchaToken) })

  validateCaptchaToken = (captchaToken: any) => typeof captchaToken === 'string' ? captchaToken : '' 

  validateShowOnboarding = () => this.state.onboarding?.steps && this.state.onboarding.steps.length 

  onClickBtnModal = () => this.setState({showError: false})

  onClickRegister = () => this.setState({showtype: 'register'})
  
  onClickLogin = () => this.setState({showtype: 'login'})

  backShowtypeOptions = () => this.setState({showtype: 'none'});
  
  renderUsernameField = (isPhone: boolean, validateUserFunc: userValidationFunc) => {
    const { countryCode } = this.state

    if (isPhone && phoneAsUsernameCountries.includes(countryCode)) {
      return (
        <div className='form-container'>
          <PhoneUsernameField validateUser={validateUserFunc} />
        </div>
      )
    }

    return <DniField countryCode={countryCode} properties={{ validateUser: validateUserFunc, informativeText: '' }} />
  }

  goStore = () => {
    const { appVersion } = this.state
    if (appVersion && appVersion.update && appVersion.url) {
      AppLauncher.openUrl({ url: appVersion.url })
    }
    this.mounted = false
  }

  skipUpdate = () => {
    var myDate = new Date()
    myDate.setHours(myDate.getHours() + 24)
    // localStorage.setItem('@update', JSON.stringify({ nextUpdate: myDate.getTime() }))
    // this.setState({ nextUpdate: myDate })
    this.mounted = false
  }

  render() {
    const {
      showtype,
      appInfo,
      loginShowCases,
      countryName,
      isFormValid,
      onboarding,
      pageState,
      valueInputText,
      validationInput,
      validationText,
      loading,
      loadingCover, 
      showError,
      messageError,
      appVersion,
    } = this.state



    const { captchaRef } = this
    return (
      <IonPage className="login-page" >
        <LoadingCover isLoading={loadingCover} />
        {(pageState === PageStates.ONBOARDING && this.validateShowOnboarding()) && 
          <GlobalComponents.Onboarding
            loginShowCases={loginShowCases?.length && loginShowCases[0]}
            onboarding={onboarding.steps}
            nextPagePath={'login'}
            onclickNextPagePath={this.onclickNextPagePathOnboarding}
            onClickInOnboarding={this.onClickInOnboarding}
            from="login"
          />
        }
        {pageState === PageStates.LOGIN && 
          <IonContent >
            {appVersion?.s &&
              appVersion?.url &&
              appVersion?.shouldUpdate && (
                <GuruSlider 
                  pager={false} 
                >
                  <GuruSlide>
                    <IonAlert
                      isOpen={appVersion?.update}
                      onDidDismiss={(): void => {} }
                      backdropDismiss={appVersion?.required ? false : true}
                      header={appVersion?.header}
                      message={appVersion?.required ? appVersion?.messageRequired : appVersion?.message}
                      buttons={
                        appVersion?.required
                          ? [
                            {
                              text: appVersion?.buttonUpdate,
                              handler: this.goStore,
                            },
                          ]
                          : [
                            {
                              text: appVersion?.buttonUpdate,
                              handler: this.goStore,
                            },
                            {
                              text: appVersion?.buttonSkip,
                              handler: this.skipUpdate,
                            },
                          ]
                      }
                    />
                  </GuruSlide>
                </GuruSlider>
              )}

            <AlertModal
              label={'Algo salió mal...'}
              text={messageError}
              buttonText='Ok'
              isOpen={showError}
              buttonAction={this.onClickBtnModal}
              onDismiss={this.onClickBtnModal}
            />
            {loginShowCases && loginShowCases.length > 0  && 
              loginShowCases.map((item:any, index:number) =>{
                return(
                  <div className='container-img-user' key={index}>
                    <div className="container-text">
                      <div className="text">
                        {showtype !== 'none' &&
                          <IonIcon slot="icon-only" onClick={this.backShowtypeOptions} className="arrow-back" src={arrowBackWhite}  />
                        }
                      </div>
                    </div>
                    {/* <img className="logoImage" src={logo} alt="Imagen del logo"/> */}
                    <IonImg className="img-user" src={`${process.env.REACT_APP_BFF_IMAGE}users-login/${item.img}`}  /> 
                  </div>)
              })}
            <div className="container-login-register">
                {showtype !== 'none' && 
                  <div className='formtype-header-container'>
                    <span className='formtype-text'>
                      {showtype === 'login' ? 'INICIA SESIÓN' : 'REGÍSTRATE'}
                    </span>
                  </div>
                }
                {showtype === 'none' && 
                  <div className='welcome-container'>
                    <div className="welcome-title-container">
                      <h1 className="welcome-title">
                        ¡Bienvenido a 
                      </h1>
                      <h1 className="welcome-title">Almacén Gurú!</h1>
                      <span className='welcome-text'>
                        Abastece tu negocio con tus marcas y proveedores favoritos.
                      </span>
                    </div>
                    <div className='btn-showtype-container'>
                      <ButtonComponent
                        className="btn-secondary log-in-btn btn-showtype"
                        disabled={false}
                        text="Inicia sesión"
                        onClick={this.onClickLogin}
                      />
                      <ButtonComponent
                        className="btn-primary sign-in-btn btn-showtype"
                        disabled={false}
                        text="Regístrate"
                        onClick={this.onClickRegister}
                      />
                      <div className='welcome-divider'></div>
                      <div className="showtype-invited-btn"> 
                        <span 
                          onClick={() => this.props?.history?.push('/validate-comune')}
                        >
                          Continuar como <b>usuario invitado</b>
                        </span>
                      </div>
                    </div>
                    <ContactSupport />
                  </div>
                }
                {showtype === 'login' &&
                  <div className="form">

                    {this.renderUsernameField(true, this.validateUser)}

                    <div className='form-container'>
                      <TextInput 
                        iconDefault="icn_password" 
                        iconInvalid="icn_password-red"
                        iconValid="icn_password-blue"
                        onChange={this.onChangeInput} 
                        placeholder="Ingresa tu contraseña"
                        label="Contraseña"
                        isValid={validationInput}
                        error="La contraseña debe tener de 4 a 12 caracteres"
                        value={valueInputText}
                        toValidate={validationText}
                        type="password"
                        maxLength={12}
                        autoComplete="off"
                      />
                    </div>
                    <br />
                    <div className="captcha-container">
                     {process.env.REACT_APP_SHOW_CAPTCHA === 'true' && 
                      <ReCAPTCHA
                          sitekey={captcha_client}
                          ref={captchaRef}
                          size="invisible"
                          badge="bottomright" // Puedes ajustar la posición según necesites
                          onChange={this.retrieveCaptchaToken}
                        />}
                    </div>
                    <div className="login-btn"> 
                      <ButtonComponent 
                        className={`btn-primary ${loading ? 'loading' : ''} login`}
                        disabled={!isFormValid}
                        onClick={this.onButtonClick}
                        text="Ingresar"
                      />
                    </div>
                    <IonGrid >
                      <IonRow >
                        <IonCol size="12"  >
                        <div className="forgotPass">
                        <IonRippleEffect ></IonRippleEffect>
                        ¿Olvidaste tu contraseña?
                        </div>
                        </IonCol>
                      </IonRow>
                      <IonRow >
                        <IonCol size="12" className="recovery-password" >
                          <a className="recovery-password" href="/validate-user">
                            <IonRippleEffect ></IonRippleEffect>
                            {"Haz click aquí"}
                          </a>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <br/>
                    <ContactSupport />
                  </div>}
            </div>
            {showtype ===  'register' && 
              <RegisterUserView goProfile={this.goProfile} renderUsernameField={this.renderUsernameField} {...this.props} />
            }

          </IonContent>}
          {pageState === PageStates.LOGIN && 
            <div className={`app-version ${appInfo?.platform}`}>
              <span>©Almacén Gurú - {countryName}</span>
              {process.env.REACT_APP_NODE_ENV !== 'production' && 
                <span className="capitalize">
                  - {process.env.REACT_APP_NODE_ENV} 
                </span>&&
                <span> - {appInfo?.platform} </span>
              }
              {(appInfo.platform === 'ios' || appInfo.platform === 'android') && 
                <span> versión {appInfo?.appVersion}</span>
              }
            </div>
          }
      </IonPage>
    )
  }
}

export default track({ page: 'Login' })(withRouter(Login))



