import React, { useState, useEffect } from 'react';
import track from 'react-tracking' 
import ProductModal from '../../pages/product-modal/ProductModal';
import { NumberUpDown } from '../number-updown/NumberUpDown';
import { formatCurrency } from '../../utils/intl';
import { eventAddToCart, eventForCart, fbAddToCartEvent, registerBasicUserInfoEvent } from '../../pages/firebase/firebaseTags';
import { CartModel } from '../../models/CartModel';
import { Product } from '../../models/ProductModel';
import Settings from '../../models/Settings';
import PromotionIcon from '../product-promotion-icon/PromotionIcon';
import { addToCart, removeFromCart } from '../../utils/cart';
import { EventEmitter } from '../../utils/events';
import { returnOptionsToPackage } from '../../utils/uomAndPackage';
import { totalPrice } from '../../clients/product';
import './ProductCard.scss';
import { countryCl, countryPe, countryMx, getCountryCode } from '../../utils/countriesTexts';

interface PastRouteInfo {
  route: string;
  paymentMethod?: string;
}

interface MetricsProps {
  addToCartSource?: string;
  deleteProductCartSource?: string;
}

interface ProductCardProps {
  settings?: Settings;
  product: Product;
  className?: string
  onProductClick?: (product: Product) => void;
  onGoToCart?: () => void;
  cartModel: CartModel;
  hidePrice?: boolean;
  history: any;
  pastRouteInfo?: PastRouteInfo;
  categoryPosition?: number;
  filterCategoryName?: any;
  needPadding?: boolean;
  onCloseCallback?: () => void;
  renderShortcut?: boolean;
  renderAsModal?: boolean;
  closeAllModals?: boolean;
  closeOneByOne?: boolean;
  metrics?: MetricsProps;
  placementName?: string
  onAddToCart?: () => void
}

const ProductCardComponent: React.FC<ProductCardProps> = (props) => {

  const {
    className,
    product,
    hidePrice,
    renderShortcut = true,
    needPadding,
    closeOneByOne,
    renderAsModal: propRenderAsModal,
    metrics,
    history,
    pastRouteInfo,
    onCloseCallback,
    onAddToCart,
    placementName
  } = props
  
  const [isOpen, setIsOpen] = useState(false);
  const [nameToRender, setNameToRender] = useState('');
  const [effectivePrice, setEffectivePrice] = useState(0);
  const [offerPrice, setOfferPrice] = useState(0);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [productModalData, setProductModalData] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [renderAsModal, setRenderAsModal] = useState(true);
  const [buttonLadderActive, setButtonLadderActive] = useState(false);
  useEffect(() => {
    const name = product.name;
    const nameToRender = isLongName(name) ? name.slice(0, 40) + '...' : name;
    const effectivePrice = product?.showPrice.price;
    const offerPrice = product && product.showPrice.offerPrice !== 0 && product.showPrice.offerPrice;
    const productModalData = setUpModalData(product);

    setNameToRender(nameToRender);
    setEffectivePrice(effectivePrice);
    setOfferPrice(offerPrice);
    setProductModalData(productModalData);
    setRenderAsModal(propRenderAsModal !== undefined ? propRenderAsModal : true);

    checkProductInCart(product);
    subscribeUpdateEvent(product);

    return () => {
      EventEmitter.unsubscribe('UpdateCartCount');
    };
  }, [product, propRenderAsModal]);

  const isLongName = (name: string) => name.length >= 25;

  const checkProductInCart = (product: Product) => {
    const cartLocal = localStorage.getItem("@cart");
    const cart = cartLocal ? JSON.parse(cartLocal) : null;
    if (!cart || !product) return;

    const cartProduct = cart.products.find(({_id}: any) => _id === product._id);
    if (cartProduct) {
      setQuantity(cartProduct.quantity || 0);
      setIsLadder(cartProduct.quantity);
    }
  };

  const subscribeUpdateEvent = (product: Product) => {
    EventEmitter.subscribe('UpdateCartCount', ({ addedProductId, quantity }: any) => {
      if (addedProductId === product._id) {
        setQuantity(quantity);
        setIsLadder(quantity);
      } else {
        const loadingCartProductsLocal = localStorage.getItem("loadingCartProducts");
        let loadingCartProducts = loadingCartProductsLocal ? JSON.parse(loadingCartProductsLocal) : [];
        if (loadingCartProducts?.length === 0) checkProductInCart(product);
      }
    });
  };

  const setIsLadder = (quantity: number) => {
    const lowerPrice = returnLowerPrice()[0];
    setButtonLadderActive(lowerPrice?.quantity === quantity);
  };

  const setUpModalData = (product: Product) => {
    const originLink = window.location.pathname.split('/');
    const originPath = originLink[1];

    let modalData: any = {
      isOpen: true,
      product,
      originPath,
      originLink,
      useInitialQuantity: true,
    };

    if (pastRouteInfo !== null) {
      modalData.fromSuggested = true;
      modalData.pastRouteInfo = pastRouteInfo;
    }

    return modalData;
  };

  const onProductClick = () => {
    if (renderAsModal) {
      setProductModalOpen(true);
    } else {
      const originLink = window.location.pathname.split('/');
      const originPath = originLink[1];
      const data = {
        fromSuggested: true,
        product,
        pastRouteInfo,
        originPath,
        originLink,
        closeOneByOne,
      };
      const routeInfo = `/vendor/${product.providerId}/product/${product._id}`;
      history.push(routeInfo, data);
    }
  };

  const closeModal = () => {
    setProductModalOpen(false);
    if (onCloseCallback && !closeOneByOne) onCloseCallback();
  };

  let timeout: NodeJS.Timeout;
  let currentQuantity: number | null = null;

  const addViaShortcut = async (productId: string, quantity: number) => {
    registerBasicUserInfoEvent({
      eventName: 'product_add_to_cart_shortcut',
      placementName
    })

    const prevQuantity = quantity;
    setQuantity(quantity);

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (currentQuantity !== quantity) addToCartHandler(productId, quantity);
    }, 50);

    if (prevQuantity === 0 && quantity > 0) {
      const priceToEvents = (await totalPrice(product.id, quantity)).price;
      const numberProductPrice = typeof priceToEvents === "string" ? parseInt(priceToEvents) : priceToEvents;
      eventAddToCart(quantity, product, priceToEvents, product.providerName, null, metrics?.addToCartSource || 'product_short_cut');
      fbAddToCartEvent(product.name, product.id, 'product', numberProductPrice);
    }

    

    // if (prevQuantity > 0 && quantity === 0) {
    //   eventForCart("delete_product_cart", product.name, product.id, product.brand, product.category, metrics?.deleteProductCartSource || 'product_short_cut');
    // }
  };

  const addToCartHandler = async (productId: string, quantity: number) => {

    if(onAddToCart) {
      onAddToCart()
    }

    const cartIdLocal = localStorage.getItem('currentCartId');
    let cartId = cartIdLocal || null;

    if (!cartId) return;

    let cartUpdateAction = 'none';
    let isInCart: boolean | null = null;
    let isInLoadingAdd: boolean | null = null;
    let isInLoadingSubtract: boolean | null = null;
    const cartLocal = localStorage.getItem("@cart");
    const cart = cartLocal ? JSON.parse(cartLocal) : null;
    const loadingCartProductsLocal = localStorage.getItem("loadingCartProducts");
    const loadingCartProducts = loadingCartProductsLocal && loadingCartProductsLocal !== 'null' 
      ? JSON.parse(loadingCartProductsLocal) 
      : { adding: [], subtracting: [] };

    const quantityMin = product?.quantityMin;
    if (cart) {
      isInCart = cart.products.some(({_id}: any) => _id === productId);
    }

    if (loadingCartProductsLocal && loadingCartProductsLocal !== 'null') {
      isInLoadingAdd = loadingCartProducts?.adding?.includes(productId);
      isInLoadingSubtract = loadingCartProducts?.subtracting?.includes(productId);
    }

    if (quantity > 0 && quantity >= quantityMin) {
      addToCart(cartId, productId, quantity);
      if ((!isInCart && !isInLoadingAdd) || currentQuantity === 0) {
        cartUpdateAction = 'add';
        loadingCartProducts.adding.push(productId);
      }
    } else {
      removeFromCart(cartId, productId);
      if (isInCart || isInLoadingAdd) {
        loadingCartProducts.subtracting.push(productId);
        cartUpdateAction = 'subtract';
      }
    }

    currentQuantity = quantity;

    localStorage.setItem('loadingCartProducts', JSON.stringify(loadingCartProducts));
    EventEmitter.dispatch('UpdateCartCount', { cartUpdateAction, addedProductId: productId, quantity });
  };

  const addViaButtonLadder = () => {
    const { _id } = product;
    
    registerBasicUserInfoEvent({
      eventName: 'product_add_to_cart_shortcut',
      placementName
    })

    setButtonLadderActive(!buttonLadderActive);
    !buttonLadderActive && addViaShortcut(_id, returnLowerPrice()[0].quantity);
    buttonLadderActive && addViaShortcut(_id, 0);
  };

  const returnLowerPrice = () => {
    return product?.promotionalDynamicDetail.map((type: any) =>
      type.name === "ladder" && type?.rules.reduce((accumulator: any, current: any) => {
        if (accumulator.quantity < current.quantity) {
          accumulator = current;
        }
        return accumulator;
      })
    );
  };

  const ButtonLadder = () => {
    const classCss = buttonLadderActive ? "container-btn-ladder-active" : "container-btn-ladder";
    const currentCountryCode = getCountryCode();
    const priceLadder = returnLowerPrice()[0].discount / product.units;
    
    const priceFormatByCountry: { [key: string]: number } = {
      [countryCl]: Math.trunc(priceLadder),
      [countryPe]: priceLadder,
      [countryMx]: priceLadder,
    };
    const total = priceFormatByCountry[currentCountryCode] || priceLadder;

    return (
      <div className={classCss}>
        <div className="btn-ladder" onClick={addViaButtonLadder}>
          <div className="quantity">Desde {returnLowerPrice()[0].quantity} {returnOptionsToPackage()[product.package]} </div>
          <div className="total">{formatCurrency(total)} c/u</div>
        </div>
      </div>
    );
  };

  const { _id, filename, brand, quantityMin, quantityMax, units, packageType, outOfStock, stock } = product;

  const isEnough = (quantity === quantityMax) || (stock && quantity === stock);
  const disableQuantitySelector = !product.vendorActiveInComune || isEnough;

  return (
    <div className={`mega-container-product-card ${className ?? ''}`}>
      {productModalOpen && 
        <ProductModal 
          renderAsModal={renderAsModal}
          closeModal={closeModal}
          {...productModalData}
          {...props}
          // initialQuantity={quantity}
          closeOneByOne={closeOneByOne}
        />
      }
      <div className={`container-product-card ${needPadding && 'has-margin has-responsive-father'}`}>
        <div className={`product-card ${needPadding ? 'has-responsive' : ''}`} /*style={{ height: renderShortcut ? "250px" : "200px" }}*/>
          <div className="body" onClick={onProductClick}>
            <div className="picture">
              <div className="container-img-ladder">
                <PromotionIcon product={product} selectedQuantity={0} iconHeight={'25px'} textSize={'14px'} />
              </div>
              <img className="img-product" src={filename} alt="Imagen del producto" />
            </div>
          </div>
          {offerPrice && !hidePrice ? (
            <div className={`footer offer ${outOfStock ? 'out-of-stock' : ''}`}>
              <span className={`tp-old ${outOfStock ? 'out-of-stock' : ''}`}>
                <s>{formatCurrency(effectivePrice)}</s>
              </span>
              <span className={`tp-offer ${outOfStock ? 'out-of-stock' : ''}`}>{formatCurrency(offerPrice)}</span>
            </div>
          ) : (
            !hidePrice && 
            <div className={`footer ${outOfStock ? 'out-of-stock' : ''}`}>
              <span>{formatCurrency(effectivePrice)}</span>
            </div>
          )}
          <div className="container-text">
            <div className="tp">{brand}</div>
            <div className="tp">{nameToRender}</div>
          </div>

          {renderShortcut && !outOfStock && product.promotionalDynamicType !== "ladder" &&
            <div className='quantity-picker-container'>
              <NumberUpDown
                value={quantity}
                valueToShow={['box', 'pack', 'piece', 'bag', 'sbox'].includes(packageType) ? quantity : quantity * units}
                min={quantityMin}
                max={stock ? stock : quantityMax}
                step={1}
                onChange={(quantity) => {
                  addViaShortcut(_id, quantity)
                  registerBasicUserInfoEvent({
                    eventName: 'product_add_to_cart_shortcut',
                    placementName
                  })
                }}
                disabled={disableQuantitySelector}
              />
            </div>
          }
          {product.promotionalDynamicType === "ladder" && !outOfStock && <ButtonLadder />}
          {outOfStock && <div className='quantity-picker-container'>Agotado</div>}
        </div>
      </div>
    </div>
  );
};

export const ProductCard =  track({page: 'ProductCard'})(ProductCardComponent)
