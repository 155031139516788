import React from 'react'

// Libraries
import {
  IonToolbar,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonTitle,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonModal,
} from '@ionic/react'
import { find } from 'lodash'
import { Device } from '@capacitor/device'

// Clients
import { getCartId, deletCart } from '../../clients/cartNew'

// Assets
import arrowBack from './../../assets/icons/arrow-back-blue.svg'
import arrowDown from './../../assets/icons/act_open_white.svg'

import close from './../../assets/icons/nav_close.svg'
import CloseBlue from '../../assets/icons/nav_close.svg'
import categoryToolbarIcon from '../../assets/icons/nav_category.svg'
import userInterno from '../../assets/icons/icono_categories_inactive.svg'
import warning from '../../assets/warning.png'
import locationIcon from '../../assets/icons/icon_location_white.svg'

// Models
import { CartModel } from '../../models/CartModel'
import Office from '../../models/Office'
import { ProviderModel } from '../../models/ProviderModel'

// Utils
import { ifExistAndMayorToZero } from '../../utils/arrays'
import { isOfficeSupported } from '../../utils/validateCurrentOffice'

// Styles
import './ToolBar.scss'
import {
  eventNotificationBack,
  eventNotificationExit,
  eventNotificationModule,
} from '../../pages/firebase/firebaseTags'
import { getLocalStorageObject } from '../../utils/localstorageData'
interface BaseButtonProps {
  icon?: string
  badge?: number
  key: string
  isOpen?: () => void
  notOffice?: boolean
  animateAtc?: any
}

interface NormalButtonProps extends BaseButtonProps {
  type?: 'normal'
  onClick?: () => void
  isOpen?: () => void
}

interface IconButtonProps extends BaseButtonProps {
  type: 'icon'
  onClick?: () => void
  isOpen?: () => void
}

interface IconBackProps {
  type: 'back'
  onClick?: any
  isOpen?: () => void
  disabled?: boolean
}

interface IconCloseProps {
  type: 'close'
  onClick?: () => void
  isOpen?: () => void
}

interface IconCategory extends BaseButtonProps {
  type: 'category'
  onClick?: () => void
}

interface BasicIcon extends BaseButtonProps {
  type: 'basicIcon'
  iconSize: string
  onClick?: () => void
  disabled?: boolean
}

type ButtonProps =
  | NormalButtonProps
  | 'menu'
  | IconButtonProps
  | IconBackProps
  | IconCategory
  | IconCloseProps
  | BasicIcon

interface Props {
  title: string
  showSearchBar?: boolean
  searchBar?: any
  primaryButtons?: ButtonProps[]
  secondaryButtons?: ButtonProps[]
  tertiaryButtons?: ButtonProps[]
  leftButtons?: ButtonProps[]
  rightButtons?: ButtonProps[]
  offices?: any
  office?: any
  singleProviderId?: any
  products?: () => void
  renderProducts?: () => void
  loadOnChangeOffice?: (office: any) => void
  secondaryHeader?: boolean
  boxShadow?: boolean
  textCenter?: boolean
  cartModel?: CartModel
  officeModel?: Office
  tree?: any[]
  onBreadCrumbClick?: (categoryId: string, nameCategory: string, i: number) => void
  oneVendor?: boolean
  bottomSheetShowem?: boolean
  showComplete?: boolean
  history?: any
  headerNotifications?: boolean
}

interface IState {
  location: any
  officeCurrent: any
  promptAlert: boolean
  event: any
  platform: string
  selectedOffice: string
}

const areOfficesValid = (officeValue: any, officesValue: any[]) =>
  officeValue && officesValue && officesValue.length > 0

const renderButton = (button: ButtonProps) => {
  const buttonCategory: any = button

  const isOfficeSupportedResult: boolean = isOfficeSupported()

  if (button === 'menu' || buttonCategory.key === 'category') {
    const user = localStorage.getItem('@user')
    const roleUser = user ? JSON.parse(user).role.name : ''

    return (
      <div className="category-container" key="category-btn">
        <IonButton key={roleUser} disabled={buttonCategory.notOffice} onClick={buttonCategory.onClick} >
          <IonIcon
            className="category-button-icon"
            src={roleUser === 'Almacenero Interno' ? userInterno : categoryToolbarIcon}           />
        </IonButton>
      </div>
    )
  }

  if (button.type === 'basicIcon') {
    return (
      <IonButton key="back" onClick={button.onClick} disabled={button.disabled} >
        <IonIcon slot="icon-only" src={button.icon} style={{ width: button.iconSize }}  />
      </IonButton>
    )
  }

  if (button.type === 'icon') {
    return (
      <IonButton key={button.key} onClick={button.onClick} >
        <IonIcon slot="icon-only" icon={button.icon}  />
        {button.badge && button.badge > 0 ? <IonBadge >{button.badge}</IonBadge> : undefined}
      </IonButton>
    )
  }
  if (button.type === 'back') {
    return (
      <IonButton
        key="back"
        onClick={() => {
          // if (window.location.pathname.split('/')[1] === 'notifications') {
          //   // const { name } = getLocalStorageObject('@user')
          //   // const timestamp = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
          //   // const { location } = getLocalStorageObject('currentOffice')
          //   // const lastNotificationType = localStorage.getItem('lastNotificationType')
          //   //   ? localStorage.getItem('lastNotificationType')
          //   //   : ''

          //   // eventNotificationBack(name, timestamp, location?.city, lastNotificationType!)
          // }

          if (button.onClick) button.onClick()
        } }
        disabled={button.disabled}       >
        <IonIcon slot="icon-only" src={arrowBack}  />
      </IonButton>
    )
  }
  if (button.type === 'close') {
    return (
      <IonButton
        key="back"
        onClick={() => {
          if (window.location.pathname === '/notifications') {
            const { name } = getLocalStorageObject('@user')
            const timestamp = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
            const { location } = getLocalStorageObject('currentOffice')

            eventNotificationExit(name, timestamp, location.city)
          }

          if (button.onClick) button.onClick()
        } }       >
        <IonIcon slot="icon-only" icon={CloseBlue}  />
      </IonButton>
    )
  }
  if (button.key === 'notifications') {
    let badge = null
    if (typeof button.badge === 'number') badge = button.badge > 0 ? button.badge : ''
    if (typeof button.badge === 'string') badge = button.badge

    return (
      <IonButton
        className="notif-button"
        disabled={!isOfficeSupportedResult || buttonCategory.notOffice}
        onClick={() => {
          const currentPath: string = window.location.pathname

          const originModule: string = currentPath.substring(1, currentPath.length)
          const { name } = getLocalStorageObject('@user')
          const timestamp = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
          const { location } = getLocalStorageObject('currentOffice')

          // eventNotificationModule(originModule, name, timestamp, location?.city)

          if (button.onClick) button.onClick()
        } }
        key={button.key}       >
        <IonIcon src={button.icon}  />
        <IonBadge className="notif-badge" >{badge}</IonBadge>
      </IonButton>
    )
  }

  if (button.key === 'cart') {
    return (
      <IonButton
        disabled={!isOfficeSupportedResult || buttonCategory.notOffice}
        onClick={button.onClick}
        key={button.key}       >
        <IonIcon src={button.icon}  />
        {button.badge && button.badge > 0 ? (
          <IonBadge className={`cart-badge-btn ${button.animateAtc}`} >{button.badge}</IonBadge>
        ) : (
          ''
        )}
      </IonButton>
    )
  }

  return (
    <IonButton disabled={buttonCategory.notOffice} onClick={button.onClick} key={button.key} >
      <IonIcon src={button.icon} style={{ marginRight: button.key === 'search' ? '8px' : '' }}  />
      {button.badge && button.badge > 0 ? <IonBadge >{button.badge}</IonBadge> : ''}
    </IonButton>
  )
}

export default class ToolBar extends React.PureComponent<Props, IState> {
  state: IState = {
    location: '',
    officeCurrent: '',
    promptAlert: false,
    event: null,
    platform: '',
    selectedOffice: '',
  }

  popAlert = (val: boolean) => {
    this.setState({ promptAlert: val })
  }

  onOpenChangeOffice = async (event: any | undefined) => {
    const cart = await localStorage.getItem('@cart')
    const cartLength = cart ? JSON.parse(cart).products.filter((product: any) => !product.paid).length : 0
    const currentOfficeString = localStorage.getItem('currentOffice')
    const currentOffice = currentOfficeString ? JSON.parse(currentOfficeString) : null
    const value = event && event.target && event.target.value
    if (currentOffice._id !== value) {
      if (cartLength > 0) {
        this.setState({ event }, () => this.popAlert(true))
      } else {
        this.changeOffice(event)
      }
    }
  }

  changeOffice = async (event: any) => {
    const { offices, loadOnChangeOffice, officeModel } = this.props;
    const value = event && event.detail && event.detail.value;
  
    const office = offices.find((office: any) => office._id === value);
  
    if (!office) {
      console.error('No office found with the selected ID');
      return;
    }
  
    this.setState({
      location: office.location,
      selectedOffice: value,
    });
  
    this.popAlert(false);
  
    if (value && loadOnChangeOffice) {
      localStorage.setItem('currentOffice', JSON.stringify(office));
      
      // Use setCurrentOffice instead of setOffice
      officeModel?.setOffice(office);
  
      const idCart = await getCartId();
  
      if (idCart?.data?._id) {
        const deleteCart = await deletCart(idCart.data._id);
        if (Object.keys(deleteCart).length > 0 && deleteCart.message === 'Cart was closed') {
          localStorage.removeItem('@cart');
        }
      }
  
      const provider = new ProviderModel();
      await provider.getHome();
      loadOnChangeOffice(office);
    }
  }

  async componentDidMount() {
    const currentOffice = localStorage.getItem('currentOffice')
    const platform = (await Device.getInfo()).platform
    const currentOfficeObject = currentOffice ? JSON.parse(currentOffice) : null
    const currentOfficeSelected = currentOfficeObject ? currentOfficeObject._id : ''

    this.setState({
      platform,
      officeCurrent: currentOffice ? currentOffice : null,
      selectedOffice: currentOfficeSelected,
    })
  }

  modalAlert = () => {
    const { promptAlert, event } = this.state

    return (
      <IonModal className="alert-confirmation" backdropDismiss={false} isOpen={promptAlert}>
        <div className="content">
          <div className="header">
            <IonIcon className="close-toolbar-icon" icon={close} onClick={() => this.popAlert(false)}  />
          </div>
          <div className="container-info-productModal">
            <div className="img-container">
              <div className="img" style={{ backgroundImage: `url(${warning})` }}></div>
            </div>
            <div className="container-title-modal">¡ESPERA!</div>
            <div className="info-modal">Si cambias de dirección perderás los productos ya agregados al carro.</div>
          </div>
          <div className="footer">
            <div className="confirm" onClick={() => this.changeOffice(event)}>
              CONTINUAR
            </div>
          </div>
        </div>
      </IonModal>
    )
  }

  onBreadCrumbClick = (categoryId: string, nameCategory: string, i: number) => {
    if (this.props.onBreadCrumbClick !== undefined) {
      this.props.onBreadCrumbClick(categoryId, nameCategory, i)
    }
  }

  mapTree = () => {
    const { tree } = this.props
    if (tree) {
      const reverseTree = tree.reduceRight((previous, current) => {
        previous.push(current)
        return previous
      }, [])
      return (
        <div className={'breadcrumbs-text'}>
          {reverseTree.map((treeElement: any, i: number) => {
            if (i < tree.length - 1) {
              return (
                <div className="breadcrumb-element" key={treeElement.id}>
                  <div className="breadcrumb">
                    {'\u00a0'}
                    {i > 0 ? ' > ' : ''}
                    {'\u00a0'}
                  </div>
                  <div className="breadcrumb-tag">
                    {treeElement.name.length > 15 ? this.sliceText(treeElement.name, 15) : treeElement.name}
                  </div>
                </div>
              )
            }
            return null
          })}
        </div>
      )
    }
  }

  sliceText = (text: string, size: number = 14) => text.slice(0, size) + '...'

  classNameTitle = () => {
    const { textCenter, oneVendor } = this.props
    if (oneVendor) return 'title-one-vendor'
    if (!textCenter) return 'title-aligned-left'
    return 'title-center-aligned'
  }

  renderOfficesSelect = (defaultOffice: any, offices: any) => {
    if (!areOfficesValid(defaultOffice, offices)) return <></>

    const isSingleOffice = offices?.length === 1
    const officeNameText = defaultOffice?.location?.address || ''

    return (
      <div className="office-selection" style={{ pointerEvents: isSingleOffice ? 'none' : 'all' }}>
        <div className="office-selection__text-wrapper">
          <div className="office-selection__info-text">
            <img src={locationIcon} alt="office location icon" />
            <span> Recibe en:</span>
          </div>
          <p className="paragraph-3 no-margin">{officeNameText}</p>
        </div>

        {!isSingleOffice && <img src={arrowDown} className="office-selection__arrow-icon" alt="arrow down" />}

        <IonItem >
          <IonSelect
            onIonChange={this.onOpenChangeOffice}
            interface="action-sheet"
            cancelText="Cancelar"
            placeholder="Sucursal"
            value={this.state.selectedOffice}
            interfaceOptions={{
              header: 'Elige una sucursal para recibir tu pedido',
              className: 'custom-action-sheet',
            }}           >
            {offices.map(({ _id, location: { address, deparment, province, city } }: any) => (
              <IonSelectOption key={_id} value={_id} >
                {address}, {city}
                {province ? ', ' + province + ', ' : ''}
                {deparment ? deparment : ''}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </div>
    )
  }

  renderTitleConditional = (showSearchBar: any, searchBar: any, tree: any, title: any) => {
    if (showSearchBar) {
      return searchBar
    } else if (!showSearchBar && !ifExistAndMayorToZero(tree)) {
      return (
        <>
          {this.props.headerNotifications ? (
            <span className={`${this.classNameTitle()} h1-title-notifications`}>
              {title.length > 14 && !this.props.showComplete ? this.sliceText(title, 14) : title}
            </span>
          ) : (
            <IonTitle className={`${this.classNameTitle()} h1-title`} >
              {title.length > 14 && !this.props.showComplete ? this.sliceText(title, 14) : title}
            </IonTitle>
          )}
        </>
      )
    } else if (!showSearchBar && ifExistAndMayorToZero(tree)) {
      return this.mapTree()
    }
  }

  render() {
    const {
      primaryButtons,
      secondaryButtons,
      tertiaryButtons,
      leftButtons,
      rightButtons,
      title,
      offices,
      office,
      secondaryHeader,
      showSearchBar,
      searchBar,
      oneVendor,
      tree,
      bottomSheetShowem,
    } = this.props
    const { platform, promptAlert } = this.state
    const toolbarClasses = `ds-content-page no-box-shadow 
      ${secondaryHeader ? 'secondaryHeader' : 'primaryHeader'} 
      ${platform === 'ios' ? 'size-ios' : 'size-default'}`

    return (
      <IonToolbar mode="ios" className={toolbarClasses} style={{ zIndex: bottomSheetShowem ? '-2' : '0' }} >
        {promptAlert && this.modalAlert()}
        <IonButtons slot="primary" >{tertiaryButtons && tertiaryButtons.map(renderButton)}</IonButtons>
        <IonButtons slot="primary" >{primaryButtons && primaryButtons.map(renderButton)}</IonButtons>
        {
          <IonButtons slot="secondary" >
            {secondaryButtons && !oneVendor && secondaryButtons.map(renderButton)}
          </IonButtons>
        }
        <IonButtons slot="left" >{leftButtons && leftButtons.map(renderButton)}</IonButtons>
        <IonButtons slot="right" >{rightButtons && rightButtons.map(renderButton)}</IonButtons>
        {this.renderOfficesSelect(office, offices)}
        {!areOfficesValid(office, offices) && (
          <React.Fragment>{this.renderTitleConditional(showSearchBar, searchBar, tree, title)}</React.Fragment>
        )}
      </IonToolbar>
    )
  }
}
