import React from 'react'

import ProductModal from '../../pages/product-modal/ProductModal'
import { NumberUpDown } from '../number-updown/NumberUpDown'
import { formatCurrency } from '../../utils/intl'
import { eventAddToCart, eventForCart, fbAddToCartEvent, registerBasicUserInfoEvent } from '../../pages/firebase/firebaseTags'

import { CartModel } from '../../models/CartModel'
import { Product } from '../../models/ProductModel'
import Settings from '../../models/Settings'

import PromotionIcon from '../product-promotion-icon/PromotionIcon'
import { addToCart, removeFromCart } from '../../utils/cart'
import { EventEmitter } from '../../utils/events'
import { returnOptionsToPackage } from '../../utils/uomAndPackage'
import { totalPrice } from '../../clients/product'
import track from 'react-tracking' 
import './ProductCard.scss'
import { countryCl, countryPe, countryMx, getCountryCode } from '../../utils/countriesTexts'

interface PastRouteinfo {
  route: string
  paymentMethod?: string
}

interface MetricsProps {
  addToCartSource?: string
  deleteProductCartSource?: string
}
interface IProps {
  settings?: Settings
  product: Product
  onProductClick?: (product: Product) => void
  onGoToCart?: () => void
  tracking?: any
  cartModel: CartModel
  hidePrice?:boolean
  history:any
  pastRouteInfo?:  PastRouteinfo
  categoryPosition?: number
  filterCategoryName?: any
  needPadding?: boolean
  onCloseCallback?: () => void
  renderShortcut?: boolean,
  renderAsModal?: boolean
  closeAllModals?: boolean
  closeOneByOne?: boolean
  metrics?: MetricsProps
}

interface IState {
  isOpen: boolean
  nameToRender: any
  effectivePrice: any
  offerPrice: any
  productModalOpen: boolean
  productModalData: any
  quantity: number
  isEnough: boolean
  renderAsModal: boolean
  buttonLadderActive: boolean
}
class ProductCard extends React.Component<IProps, IState> {
  state: IState = {
    isOpen: false,
    nameToRender: '',
    effectivePrice: 0,
    offerPrice: 0,
    productModalOpen: false,
    productModalData: {},
    quantity: 0,
    isEnough: true,
    renderAsModal: true,
    buttonLadderActive: false
  }

  componentDidMount() {
    const { product, renderAsModal } = this.props
    const { name } = product

    const nameToRender = this.isLongName(name) ? name.slice(0,40) + '...' :  name
    const effectivePrice = product?.showPrice.price
    const offerPrice = product && product.showPrice.offerPrice !== 0 && product.showPrice.offerPrice
    const productModalData = this.setUpModalData(product)

    let renderProductAsModal = renderAsModal !== undefined ? renderAsModal : true

    this.setState({
      nameToRender,
      effectivePrice,
      offerPrice,
      productModalData,
      renderAsModal: renderProductAsModal,
    })

    this.checkProductInCart(product)
    this.suscribeUpdateEvent(product)
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  checkProductInCart = (product: any) => {
    const cartLocal = localStorage.getItem("@cart")
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    if (!cart || !product) return  

    const cartProduct = cart.products.find(({_id}: any) => _id === product._id) 
    if (cartProduct){
       this.setState({ quantity: cartProduct.quantity || 0 })
       this.setIsLadder(cartProduct.quantity)
    }
  }

  suscribeUpdateEvent = (product: any) => {

    EventEmitter.subscribe('UpdateCartCount', ({ addedProductId, quantity }: any) => {
      if (addedProductId === product._id) {
        this.currentQuantity = quantity
       
        this.setState({ quantity })
        this.setIsLadder(quantity)
      } else {
        const loadingCartProductsLocal = localStorage.getItem("loadingCartProducts")
        let loadingCartProducts = loadingCartProductsLocal ? JSON.parse(loadingCartProductsLocal) : []
        if (loadingCartProducts?.length === 0) this.checkProductInCart(product)
      }
    })
  }

  setIsLadder = (quantity: any) => {
    if(this.returnLowerPrice()[0]?.quantity === quantity){
      this.setState({  buttonLadderActive: true })
    } else {
      this.setState({  buttonLadderActive: false })
    }
  }

  setUpModalData = (product: any) => {
    const { pastRouteInfo = null } = this.props    
    const originLink = window.location.pathname.split('/')
    const originPath = originLink[1]

    let modalData: any = {
      isOpen: true,
      product,
      originPath,
      originLink,
      useInitialQuantity: true,
    }

    if(pastRouteInfo !== null) {
      modalData.fromSuggested = true
      modalData.pastRouteInfo = pastRouteInfo
    }
    
    return modalData
  }

  onProductClick = () => {
    const { renderAsModal } = this.state
    const { product, history, pastRouteInfo = null, closeOneByOne } = this.props    

    if(renderAsModal) {  
      this.setState({ productModalOpen: true })

    } else {
      const originLink = window.location.pathname.split('/')
      const originPath = originLink[1]
      const data = {
        fromSuggested: true,
        product,
        pastRouteInfo,
        originPath,
        originLink,
        closeOneByOne,
      }
      const routeInfo = `/vendor/${product.providerId}/product/${product._id}`
      return history.push(routeInfo, data)
    }
  }

  dismissModal = () => {
    setTimeout(() => {
      this.setState({
        isOpen: false,
      })
    }, 10)
  }

  private renderModal() {
    const { isOpen } = this.state
    const { product, history } = this.props
    const originLink = window.location.pathname.split('/')
    const originPath = originLink[1]

    const data = {
      isOpen,
      product,
      originPath,
      originLink,
    }
    
    const routeInfo = `/vendor/${product.providerId}/product/${product._id}`
    history.push(routeInfo, data)
    return true
  }

  validateIfExistLadders = (ladder:any) => ladder && ladder.length > 0

  isLongName = (name: string) => name.length >= 25 

  closeModal = () => {
    const { onCloseCallback, closeOneByOne } = this.props
    this.setState({ productModalOpen: false }) 
    if(onCloseCallback && !closeOneByOne) onCloseCallback()
  }

  timeout:any = null
  currentQuantity: any = null

  addViaShortcut = async (productId: any, quantity: number) => {
    const prevQuantity = this.state.quantity
    this.setState({ quantity })

    const { product } = this.props
    
    clearTimeout(this.timeout)
    
    this.timeout = setTimeout(() => {
      if (this.currentQuantity !== quantity) this.addToCart(productId, quantity)
    }, 1000)

    
    if (prevQuantity === 0 && quantity > 0) {
      const priceToEvents = (await totalPrice(product.id, quantity)).price
      const numberProductPrice = (typeof priceToEvents) === "string" ? parseInt(priceToEvents) : priceToEvents
      eventAddToCart(quantity, product, priceToEvents, product.providerName, null, this.props.metrics?.addToCartSource || 'product_short_cut')
      fbAddToCartEvent(product.name, product.id, 'product', numberProductPrice)
    }

    // if (prevQuantity > 0 && quantity === 0) {
    //   eventForCart("delete_product_cart", product.name, product.id, product.brand, product.category, this.props.metrics?.deleteProductCartSource || 'product_short_cut')
    // }
  }

  addToCart = async (productId: any, quantity: number) => { 
    const cartIdLocal = localStorage.getItem('currentCartId')
    let cartId = cartIdLocal || null
    
    if(!cartId) {
      return;
    }
    
    let cartUpdateAction = 'none'
    let isInCart: any = null
    let isInLoadingAdd: any = null
    let isInLoadingSubtract: any = null
    const cartLocal = localStorage.getItem("@cart")
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    const loadingCartProductsLocal = localStorage.getItem("loadingCartProducts")
    const loadingCartProducts = loadingCartProductsLocal && loadingCartProductsLocal !== 'null' ? JSON.parse(loadingCartProductsLocal) : { adding: [], subtracting: [] }
  
    const quantityMin = this.props?.product?.quantityMin 
    if (cart) {
      isInCart = cart.products.some(({_id}: any) => _id === productId)
    }
    
    if(loadingCartProductsLocal && loadingCartProductsLocal !== 'null') {
      isInLoadingAdd = loadingCartProducts?.adding?.includes(productId)
      isInLoadingSubtract = loadingCartProducts?.subtracting?.includes(productId)
    }
    
    if(quantity > 0 && quantity >= quantityMin) {
      addToCart(cartId, productId, quantity)
      if((!isInCart && !isInLoadingAdd) || this.currentQuantity === 0) {
        cartUpdateAction = 'add'
        loadingCartProducts.adding.push(productId)
      }
    } else {
      removeFromCart(cartId, productId)
      if(isInCart || isInLoadingAdd) {
        loadingCartProducts.subtracting.push(productId)
        cartUpdateAction = 'subtract'
      }
    }

    this.currentQuantity = quantity

    localStorage.setItem('loadingCartProducts', JSON.stringify(loadingCartProducts))
    EventEmitter.dispatch('UpdateCartCount', { cartUpdateAction, addedProductId: productId, quantity })
  }

  buttonLadder = () => {
    const { buttonLadderActive } = this.state
    const { product } = this.props
    const { units} = product
    const productSelect:any = product
    const classCss = !buttonLadderActive ? "container-btn-ladder" : "container-btn-ladder-active" 
    const priceLadder = this.returnLowerPrice()[0].discount/units
    let total 
    
    const currentCountryCode = getCountryCode()

    const priceFormatByCountry: any = {
      [countryCl]: Math.trunc(priceLadder),
      [countryPe]: priceLadder,
      [countryMx]: priceLadder,
    }

    total = priceFormatByCountry[currentCountryCode] || total

    return(
      <div className={classCss}>
        <div className="btn-ladder" onClick={this.addViaButtonLadder}>
          <div className="quantity">Desde {this.returnLowerPrice()[0].quantity } {this.findPairToShowValue(returnOptionsToPackage(),productSelect.package)} </div>
          <div className="total">{formatCurrency(total)} c/u</div>
        </div>
      </div>
    )
  }

  findPairToShowValue = (options:any, name: string) => options[name]

  addViaButtonLadder = () => {
    const { buttonLadderActive } = this.state
    const { product } = this.props
    const { _id } = product
 
    this.setState({buttonLadderActive: !buttonLadderActive})
    !buttonLadderActive && this.addViaShortcut(_id, this.returnLowerPrice()[0].quantity)
    buttonLadderActive && this.addViaShortcut(_id, 0)
  }

  returnLowerPrice = () =>{
    const { product } = this.props
   
    const productSelect:any = product

    return (
      productSelect?.promotionalDynamicDetail.map((type:any) => 
        type.name === "ladder" && type?.rules.reduce((acumulator:any, current:any) =>{
          if(acumulator.quantity < current.quantity) {
            acumulator = current;
          }
          return acumulator;
         }) 
      )
    )
  }

  render() {
    const { product, hidePrice, renderShortcut, needPadding, closeOneByOne } = this.props
    const { isOpen, nameToRender, effectivePrice, offerPrice, productModalOpen, productModalData, quantity, renderAsModal } = this.state
    const { _id, filename, brand, quantityMin, quantityMax, units, packageType, outOfStock, stock } = product

    const renderNumberUpDown = renderShortcut !== undefined ? renderShortcut : true
    const isEnough = (quantity === quantityMax) || (stock && quantity === stock ? true : false)
    
    const disableQuantitySelector = !product.vendorActiveInComune || isEnough
    const productSelect:any =  product 

    return (
      <> 
        {productModalOpen && 
          <ProductModal 
            renderAsModal={renderAsModal}
            closeModal={this.closeModal}
            {...productModalData}
            {...this.props}
            initialQuantity={quantity}
            closeOneByOne={closeOneByOne}
          />
        }
        <div className={`container-product-card ${needPadding && 'has-margin has-responsive-father'}`}>
          {isOpen && this.renderModal()}
          <div className={`product-card ${needPadding ? 'has-responsive' : ''}`} style={{ height: renderNumberUpDown ? "250px" : "200px" }}>
            <div className="body" onClick={this.onProductClick}>
              <div className="picture" >
                <div className="container-img-ladder">
                  <PromotionIcon product={product} selectedQuantity={0} iconHeight={'25px'} textSize={'14px'}/>
                </div>
                <img className="img-product" src={filename} alt="Imagen del producto"/>
              </div>
            </div>
            {offerPrice && !hidePrice ? (
              <div className="footer offer" onClick={this.onProductClick}>
                <span className={`tp-old ${outOfStock ? 'out-of-stock' : ''}`}>
                  <s>{formatCurrency(effectivePrice)}</s>
                </span>
                <span className={`tp-offer ${outOfStock ? 'out-of-stock' : ''}`}>{formatCurrency(offerPrice)}</span>
              </div>
            ) : (
              !hidePrice && 
              <div className={`footer ${outOfStock ? 'out-of-stock' : ''}`} onClick={this.onProductClick}>
                <span>{formatCurrency(effectivePrice)}</span>
              </div>
            )}
            <div className="container-text">
              <div className="tp">{brand}</div>
              <div className="tp" >{nameToRender}</div>
            </div>

            {Boolean(renderNumberUpDown) && !outOfStock && productSelect.promotionalDynamicType !== "ladder" &&
              <div className='quantity-picker-container'>
                <NumberUpDown
                  value={quantity}
                  valueToShow={packageType === 'box' || 'pack' || 'piece' || 'bag' || 'sbox' ? quantity : quantity * units}
                  min={quantityMin}
                  max={stock ? stock : quantityMax}
                  step={1}
                  onChange={(quantity) => this.addViaShortcut(_id, quantity)}
                  disabled={disableQuantitySelector}
                />
              </div>
            }
            {productSelect.promotionalDynamicType === "ladder" && !outOfStock && this.buttonLadder()}
            {outOfStock && <div className='quantity-picker-container'>Agotado</div>}
          </div>
        </div>
      </>
    )
  }
}

export default track({page: 'ProductCard'})(ProductCard)
