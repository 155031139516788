import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonIcon, IonSpinner, IonModal } from '@ionic/react'
import { StaticContext } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CardNotification from './CardNotification'
import { getCouponsActive } from '../../clients/coupon'
import { registerNotificationEvent } from '../firebase/firebaseTags'
import moment from 'moment'

//Models
import Office from '../../models/Office'
import { CartModel } from '../../models/CartModel'
import Notifications from '../../models/Notifications'
import { Coupon } from '../../models/Coupon'
import { ProductModel } from '../../models/ProductModel'

//Iconos
import Garbage from './../../assets/icons/act_delete.svg'

//Utils
import { dateToStringFormat } from '../../utils/dates'

// Update App
import { AppLauncher } from '@capacitor/app-launcher'
import { Device } from '@capacitor/device'
import { getDeepLinkRouteData } from '../deeplinks/deeplinksRoutes'

import './Notifications.scss'
import {
  applyCouponEvent,
  eventNotificationCta,
  sendMetricWithSource,
} from '../firebase/firebaseTags'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import { getLocalStorageObject } from '../../utils/localstorageData'

interface IPathParams {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  offices: Office
  coupons: Coupon
  cartModel: CartModel
  notification: Notifications
  refreshNotifications: any
  showNotification: any
}

const messageModal = [
  {
    status: 400,
    header: '¡Felicitaciones!',
  },
  {
    status: 200,
    header: '¡Felicitaciones!',
  },
  {
    status: 404,
    header: 'Código Incorrecto',
  },
  {
    status: 409,
    header: '',
  },
]

const OpenNotifications: React.FC<IProps> = (props) => {
  const [openNotification, setOpenNotification] = useState<any>({})
  const [couponReturned, setCouponReturned] = useState<any>(null)
  const [user, setUser] = useState<any>({})
  const [showModal, setShowModal] = useState<boolean>(false)
  const [header, setHeader] = useState<any>('')
  const [message, setMessage] = useState<any>('')
  const productModel = new ProductModel()

  useEffect(() => {
    get()
  }, []) 

  const get = async () => {
    const {
      match: { params },
    } = props

    const user = localStorage.getItem('@user')
    const userId = user ? JSON.parse(user) : ''
    setUser(userId)

    const notificationsUser = await props.notification.getNotifications(userId.id)
    const paramsId: any = params
    const findNotifications = notificationsUser.docs.find((item: any) => item._id === paramsId.id)

    refreshReadNotifications(notificationsUser)
    findNotifications !== undefined && setOpenNotification(findNotifications)
    const { title, body } = findNotifications
    if (title === 'Cupón devuelto') {
      try {
        const activeCoupons = await getCouponsActive()
        const couponCode = body.split(' ')[2]
        const coupun = activeCoupons.find((coupon: any) => coupon.code === couponCode)
        setCouponReturned(coupun)
      } catch (error) {
        setCouponReturned(null)
      }
    }
  }

  const refreshReadNotifications = async (notificationsUser: any) => {
    const unreadNotifications = notificationsUser.docs.filter(({ isOpened }: any) => !isOpened)
    const unreadNotifLength = unreadNotifications?.length
    const lastUnreadNotif = unreadNotifications[0]?._id
    localStorage.setItem('currentNotifications', String(unreadNotifLength))
    localStorage.setItem('lastNotification', String(lastUnreadNotif))
  }

  const deleteNotification = async (idNotification: any) => {
    await props.notification.deleteNotification(idNotification)
    goBack()
  }

  const goBack = async () => {
    props.refreshNotifications && props.refreshNotifications()
    props?.history?.push(`/notifications`)
  }

  const validateCoupon = async () => {
    const resultValidateCoupon = await props.coupons.countReferal(openNotification.couponCode, user.id)

    if (Object.keys(resultValidateCoupon).length > 0) {
      applyCouponEvent(openNotification?.couponCode?.toUpperCase())

      setShowModal(true)
      const message: any = messageModal.find((item: any) => item.status === resultValidateCoupon.status)
      setHeader(message.header)
      setMessage(resultValidateCoupon.respuesta)
    }
  }

  const modalCoupon = () => {
    return (
      <IonModal className="modal-validate-coupon" backdropDismiss={false} isOpen={showModal}>
        <div className="content-validate-coupon">
          <div className="container-title-validate-coupon">{header}</div>
          <div className="subTitle-validate-coupon">{message}</div>
          <div className="footer-validate-coupon" onClick={() => showModalCoupon()}>
            ok
          </div>
        </div>
      </IonModal>
    )
  }

  const onGoDeeplink = async (deeplink: string) => {
    if (deeplink.includes('almacenguru.cl') || deeplink.includes('localhost')) {
      const history = props.history
      const dependencies = [
        {
          name: 'getProduct',
          get: productModel.getProductById,
        },
      ]

      registerDeeplinkEvent(deeplink)
      const routeData = await getDeepLinkRouteData(deeplink, dependencies)
      history.push(routeData.route, routeData.params)
    } else {
      const infoApp = await Device.getInfo()
      if (infoApp.platform === 'web') {
        window.open(deeplink, '_blank')
      } else {
        AppLauncher.openUrl({ url: deeplink })
      }
    }
  }

  const registerDeeplinkEvent = (deeplink: string) => {
    const deeplinkRoutes = deeplink.split('/')
    if (deeplinkRoutes.includes('home-providers')) sendMetricWithSource('view_vendor', 'notifications')
    if (deeplinkRoutes.includes('provider') && deeplinkRoutes.includes('category'))
      sendMetricWithSource('view_categories', 'notifications')
    // if (deeplinkRoutes.includes('vendor') && deeplinkRoutes.includes('product'))
      // TODO: comentado por mientras sendMetricWithSource('view_product', 'notifications')
  }

  const renderOpenedNotification = () => {
    const { _id, title, createdAt, body, image, couponCode, deeplink, subject } = openNotification
    localStorage.setItem('lastNotificationType', subject ? subject : '')

    let notifDate = ''
    if (createdAt) notifDate = dateToStringFormat(new Date(createdAt))

    if (!validateOpenNotification()) {
      return (
        <div className="loading-section-notification">
          <p className="loading-notification">Cargando notificación</p>
          <IonSpinner className="spinner-mid" name="crescent"  />
        </div>
      )
    }

    const handleOnClickDeleteNotification = () => {
      registerNotificationEvent({
        eventName: 'notification_delete',
        placementName: 'notification_open',
        notificationId: openNotification._id,
        notificationTitle: openNotification.title,
        notificationSubject: openNotification?.subject
      })
      // eventNotificationDelete(subject, name, timestamp, location?.city)

      deleteNotification(_id)
    }

    return (
      <>
        <div className="container-open">
          <div className="header-open-notification">
            <div className="left-title-container">
              <span className="createdAt">{notifDate}</span>
            </div>
            <div className="garbage">
              <IonIcon
                className="icon"
                src={Garbage}
                onClick={handleOnClickDeleteNotification}               
              />
            </div>
          </div>

          <h4 className="title-open-notif">{title}</h4>

          <div className="card-open-notification">
            <div className="container-body">
              <p className="body">{body}</p>
              {validateImgRender() && <img className="img-notif" src={`${image}`} alt="sin imagen" />}
            </div>
            {validateDeeplinkRender() && (
              <div className="container-btn-notification">
                <ButtonComponent
                  className="btn-primary on-notification"
                  onClick={() => {
                    const { name } = getLocalStorageObject('@user')
                    const timestamp = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
                    const { location } = getLocalStorageObject('currentOffice')

                    eventNotificationCta(subject, name, timestamp, location?.city, deeplink)

                    onGoDeeplink(deeplink)
                  }}
                  text={
                    openNotification.subject === 'Noticia' ||
                    openNotification.subject === 'Actualización' ||
                    openNotification.subject === 'Campaña'
                      ? 'Ir'
                      : 'Mostrar información'
                  }
                />
              </div>
            )}
            {validateCouponRender() && (
              <div className="container-btn-notification" style={{ marginTop: '16px' }}>
                <ButtonComponent
                  className="btn-primary on-notification"
                  onClick={() => {
                    validateCoupon()
                  }}
                  text={`Activar cupón ${couponCode}`}
                />
              </div>
            )}
          </div>
        </div>

        {couponReturned && (
          <CardNotification
            imgSrc={`${process.env.REACT_APP_BFF_IMAGE}coupons/${couponReturned.image.url}`}
            subject="Cupón devuelto"
            title={`$${parseFloat(couponReturned.amount).toLocaleString()} de descuento`}
            subtitleCoupon={`Compras sobre $${parseFloat(couponReturned.minimum_amount).toLocaleString()}`}
            body={`Código: ${couponReturned.code} Vence ${moment(new Date(couponReturned.date_expires)).format(
              'DD/MM/YYYY',
            )}`}
            isOpened={true}
            handleClick={() => {}}
          />
        )}
      </>
    )
  }

  const validateOpenNotification = () => Object.keys(openNotification).length > 0

  const validateCouponRender = () => openNotification.couponCode && openNotification.couponCode !== 'sin cupon'

  const validateImgRender = () => openNotification.image && openNotification.image !== 'sin imagen'

  const validateDeeplinkRender = () => openNotification.deeplink && openNotification.deeplink !== 'sin link'

  const showModalCoupon = () => setShowModal(false)

  return (
    <IonPage className="page-open-notification" >
      <IonContent >
        {renderOpenedNotification()}
        {showModal && modalCoupon()}
      </IonContent>
    </IonPage>
  )
}

export default withRouter(OpenNotifications)
